<template>
<sub-page>
  <template #default>
    <!-- Year Selector -->
    <v-sheet class="pa-4 mt-5 wsRoundedLight" :style="`border : 1px solid ${wsBACKGROUND}`">
      <div class="d-flex align-center">
        <ft-select
            v-model="selectedYear"
            :items="yearsSelect" >
          <h4 class="mr-3">
            {{ $t('Year') }} : {{ selectedYear }}
            <v-icon :color="wsACCENT">mdi-menu-down</v-icon>
          </h4>
        </ft-select>

        <ft-select
            v-model="selectedMonth"
            :items="monthsSelect" >
          <h4>
            {{ $t('Months') }} : {{ $t(`Month_${selectedMonth}`)  }}
            <v-icon :color="wsACCENT">mdi-menu-down</v-icon>
          </h4>
        </ft-select>
      </div>
    </v-sheet>

    <v-row class="my-3">
      <v-col cols="4">
        <v-sheet class="pa-5 wsRoundedLight" :style="`border : 1px solid ${wsBACKGROUND}`" >
          <h3>Total: {{ items.length }}</h3>
        </v-sheet>
      </v-col>
      <v-col cols="4">
        <v-sheet class="pa-5 wsRoundedLight" :style="`border : 1px solid ${wsBACKGROUND}`"  >
          <h3>Conversions: {{ items.filter(el=>el.conversion).length }}</h3>
        </v-sheet>
      </v-col>
    </v-row>

    <ws-data-table
        :items="items"
        :headers="headers"
        :row-action="openBusiness"
    >
      <template #item.name="{item}">
        <div class="d-flex align-center">
          <v-icon class="mr-2" :color="getColor(item)">
            {{ getIcon(item) }}
          </v-icon>
          <div>
            <h5> {{ item.name }} </h5>
            <h6>{{ item.alias }}</h6>
          </div>

        </div>


      </template>

      <template #item.user_name="{item}">
        <h5 class="wsACCENT">{{ item.phone }}</h5>
        <h6>{{ item.user_name }}</h6>
      </template>


    </ws-data-table>

  </template>

  <template #dialog>
    <ws-dialog
        :title="entityData.name"
        v-if="displayDialog"
        v-model="displayDialog"
        width="700"
        @save="handleSave"
        cancel-action
        @cancel="handleClose"
        :cancel-text="displayAddEvent ? $t('GoBack') : $t('Cancel')"
        :save-text="displayAddEvent ? $t('Add') : $t('Save')"
    >
      <template #header-after>
        <div class="d-flex align-center justify-space-between px-6">
          <ws-navigation-header
              v-model="navigation"
              :items="navigationSelect"
              style="width: 100%"
          />
          <div class="d-flex justify-end"
               v-if="!displayAddEvent && navigation === 'events'"
               style=" width : 100%; margin-bottom: -4px"
               :style="`border-bottom: 4px solid ${wsBACKGROUND};`"
          >
            <ws-button  :color="wsATTENTION"  @click="openAddEvent" left-icon="mdi-plus" text label="Додати завдання"></ws-button>
          </div>

        </div>
      </template>
      <ws-navigation
          v-model="navigation"
          :items="navigationSelect"
          hide-header
      >
        <template #item.info>
          <v-row no-gutters>
            <v-col cols="6" class="pr-2">
              <ws-select
                  v-model="entityData.current_status"
                  label="Статус"
                  :items="statusSelect"
              />
            </v-col>
            <v-col cols="6" class="pl-2">
              <ws-select
                  v-model="entityData.lead_source"
                  label="Звідки дізнався"
                  :items="leadSourceSelect"
              />
            </v-col>
          </v-row>

          <ws-select
              v-model="entityData.client_type"
              label="Тип заявки"
              :items="clientTypeSelect"
          />


          <ws-select
              v-model="entityData.interested_in_fucntions"
              label="Зацікавлений в функціях"
              :items="modulesSelect"
              multiple
          />

          <ws-text-field
              v-model="entityData.comment"
              area
              label="Примітки"
              class="mb-5"
          />

          <div v-if="entityData.current_status === 'fail'"
               :style="`border : 1px solid ${wsBACKGROUND}`"
               class="wsRoundedLight pa-5"
          >
            <h4 :style="`color : ${wsWARNING}`" class="mb-3">Відмова</h4>
            <ws-select
                v-model="entityData.refuse_reason"
                label="Причина відмови"
                :items="refuseSelect"
            />
            <ws-text-field
                v-model="entityData.refuse_comment"
                area
                label="Коментар до причини відмови"
            />
          </div>

        </template>

        <template #item.events>

          <ws-data-table
              v-if="!displayAddEvent"
              :items="entityData.tasks"
              :headers="businessTasksHeaders"
          >
            <template #item.preview="{item}">
              <h5 class="text-no-wrap">
                <v-icon class="mr-1" :color="item.is_completed ? wsSUCCESS : wsBACKGROUND">
                  {{ getEventTypeIcon(item.type) }}
                </v-icon>
                {{ item.preview }}
              </h5>
            </template>
            <template #item.date="{item}">
              <h5 class="text-no-wrap">{{ MONTH_DAY_TIME(item.date, false) }}</h5>
            </template>

          </ws-data-table>
          <div v-else>
            <ws-text-field
                v-model="addEventData.name"
                label="Назва завдання"
            />

            <ws-select
                label="Тип завдання"
                v-model="addEventData.type"
                :items="taskTypeSelect"
                class="mt-5"
            />

            <ws-text-field
                label="Опис"
                v-model="addEventData.description"
                :items="taskTypeSelect"
                area
                class="mb-5"
            />
          </div>
        </template>

      </ws-navigation>


    </ws-dialog>
  </template>



</sub-page>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "adminCrmBusinesses",
  data() {
    return {
      entityData : {},
      addEventData : {},
      displayDialog : false,
      displayAddEvent : false,

      items : [],
      selectedYear : "2024",
      selectedMonth : 1,
      date : null,
      years : [],
      months : [],

      businessTasks : [
        { name : 'Перший дзвінок'                    , date : 1723434534 , is_completed : true  , manager : 'JeffBezos' , type : 'call' },
        { name : 'Нагадування про неактивність'      , date : 1723434534 , is_completed : true  , manager : 'JeffBezos' , type : 'call' },
        { name : 'Добрий день в мене щось не працює' , date : 1713434534 , is_completed : false , manager : 'JeffBezos' , type : 'support' },
        { name : 'Передзвонити пізніше'              , date : 1721434534 , is_completed : false , manager : 'JeffBezos' , type : 'call' },
        { name : 'Запит на консультацію'             , date : 1723434534 , is_completed : false , manager : 'JeffBezos' , type : 'support' },
      ],
      navigation : 'info',


    }
  },
  computed : {
    headers() {
      return [
        { text : 'Реєстрація' , value : 'name' },
        { text : 'Контакти' , value : 'user_name' , width : 10 },
        { text : 'Залишилось днів' , value : 'days_active_left' , width : 10 },
        { text : 'MT' , value : 'materials' , width : 10 , align : 'center'},
        { text : 'ST' , value : 'students' , width : 10 , align : 'center'},
        { text : 'Поточне' , value : 'current_status' , width : 10 , align : 'center'},
        { text : 'Статус' , value : 'status' , width : 10 , align : 'center'},

      ]
    },

    taskTypeSelect() {
      return [
        { text : 'Дзвінок' , value : 'call' },
        { text : 'Відео дзвінок' , value : 'video_consult' },
        { text : 'Баг' , value : 'bug' },
        { text : 'Запит на функціонал' , value : 'feature_request' },
        { text : 'Замовлення сайту' , value : 'website' },
        { text : 'Налаштування контенту' , value : 'content' }
      ]
    },

    navigationSelect() {
      return [
        { text : 'Інформація' , value : 'info' },
        { text : 'Події'      , value : 'events'   }
      ]
    },

    businessTasksHeaders() {
      return [
        { text : 'Подія' , value : 'preview' },
        { text : 'Менеджер' , value : 'manager_name' , width : 10  },
        { text : 'Дата' , value : 'date' , width : 10 },
      ]
    },
    statusSelect() {
      return [
        { text : 'Новий' , value : "new" },
        { text : 'Ознайомлення з платформою' , value : "learning_platform" , type : 'in_process'  },
        { text : 'Налаштування контенту' , value : "preparing_content" , type : 'in_process'  },
        { text : 'Очікує на розробників' , value : "preparing_content" , type : 'waiting'  },
        { text : 'Замовив сайт' , value : "ordered_site" , type : 'success'  },
        { text : 'Замовив налаштування контенту' , value : "ordered_content" , type : 'success'  },
        { text : 'Запустив перший курс' , value : "ordered_site" , type : 'success'  },
        { text : 'Неактивний' , value : "non_active" , type : 'warning'  },

        { text : 'Повторне ознайомлення' , value : "second_try" , type : 'in_process'  },

        { text : 'Не виходить на контакт' , value : "no_contact" , type : 'warning'  },
        { text : 'Відмова' , value : "fail" , type : 'fail' },
      ]
    },
    leadSourceSelect() {
      return [
        { text : 'Google Search'                   , value : "google"               },
        { text : 'Facebook'                        , value : "facebook"    },
        { text : 'Instagram'                       , value : "instagram"    },
        { text : 'Youtube'                         , value : "youtube"    },
        { text : 'TikTok'                          , value : "toktok"         },
        { text : 'Telegram'                        , value : "telegram"      },
        { text : 'Навчався в школі яка на WeStudy' , value : "was_student"         },
        { text : 'Порадив знайомий'                , value : "advise"           },
        { text : 'Інше'                            , value : "other"           },
      ]
    },
    clientTypeSelect() {
      return [
        { text : 'Помилився адресою'                                 , value : "wrong"               },
        { text : 'Цікавиться сервісом'                               , value : "interested"          },
        { text : 'Хочу відкрити свої онлайн курси'                   , value : "beginner"            },
        { text : 'Розвиваю свою онлайн школу, шука нові інструменти' , value : "first_time_platform" },
        { text : 'Шукаю нову платформу'                              , value : "ordered_site"         },
        { text : 'Допомагаю запускати онлайн курси'                  , value : "content_manager"      },
      ]
    },
    modulesSelect() {
      return [
        { text : 'Автоматизація онлайн оплат'                 , value : "payment_system"       },
        { text : 'Система менеджменту онлайн навчання'        , value : "management"           },
        { text : 'CRM система для продажів'                   , value : "management"           },
        { text : 'Видача сертифікатів'                        , value : "certificates"         },
        { text : 'Захищене середовище для відео'              , value : "protected_video"      },
        { text : 'Система тестування студентів'               , value : "testing_system"       },
        { text : 'Автоматизовані оповіщення та розсилки'      , value : "email_newsletter"     },
        { text : 'Веб сайт для продажу курсів'                , value : "web_site"             },
        { text : 'Телеграм чат бот для продажів та оповіщень' , value : "chat_bot"             },
        { text : 'Інше' , value : "other"             },
      ]
    },
    refuseSelect() {
      return [
        { text : 'Не займаюсь курсами' , value : "refuse_wrong_lead" },
        { text : 'Змінились плани щодо запуску курсів' , value : "refuse_plans_changed" },
        { text : 'Планую перенести запуск курсів на інший час' , value : "refuse_time_later" },
        { text : 'Не вистачає потрібних для мого бізнесу функцій' , value : "refuse_functions" },
        { text : 'Не зручний інтерфейс' , value : "refuse_ux" },
        { text : 'Не розібрався із функціоналом' , value : "refuse_complex" },
        { text : 'Вартість сервісу за висока' , value : "refuse_price" },
        { text : 'Не вистачає місця на сховище' , value : "refuse_students_storage" },
        { text : 'Не вистачає квоти по кількості студентів' , value : "refuse_students_quota" },
        { text : 'Не подобається технічна підтримка' , value : "refuse_tech_support" },
        { text : 'Не задоволений платформою в цілому' , value : "refuse_general_no" },
        { text : 'Обрав іншу платформу' , value : "other_platform" },
        { text : 'Інше' , value : "other" }
      ]
    },
    yearsSelect() {
      let items = []
      this.years.forEach(el => {
        items.push( { text : el , value : el })
      })

      return items
    },
    monthsSelect() {
      let items = []
      this.months.filter(el=>el.year === this.selectedYear).forEach(el => {
        items.push( { text :  this.$t(`Month_${el.month}`)  , value : el.month })
      })

      return items
    },
  },
  watch : {
    selectedYear() {
      this.initPage()
    },
    selectedMonth() {
      this.initPage()
    }
  },
  methods : {
    ...mapActions('adminCrmSystem', [
        'GET_MONTH_REGISTER_STATISTICS',
    ]),
    ...mapActions('support', [
        'EDIT_BUSINESS_CRM_INFO',
        'GET_BUSINESS_CRM_INFO',
        'ADD_SALES_TASK'
    ]),
    handleSave() {
      if ( this.displayAddEvent ) {
        this.addEvent()
        return
      }
      this.saveBusinessInfo()
    },
    handleClose() {
      if ( this.displayAddEvent) {
        this.displayAddEvent = false
        return
      }
      this.displayDialog = false
    },
    async addEvent() {
      this.addEventData.alias = this.entityData.alias
      let result = await this.ADD_SALES_TASK(this.addEventData)
      if (!result) {
        return this.ERROR()
      }
      this.notify('added success')
      this.displayAddEvent = false
      this.entityData.tasks.push(result)
    },
    openAddEvent() {
      this.addEventData = {}
      this.displayAddEvent = true
    },
    async saveBusinessInfo() {
      let result = await this.EDIT_BUSINESS_CRM_INFO(this.entityData)
      if (!result) {
        return this.ERROR()
      }

      this.notify(this.$t('Changes saved') , 'success')
      let index = this.items.findIndex(el => el.uuid === this.entityData.uuid)
      if ( index === -1 ) {
        return
      }
      this.items[index].current_status = this.entityData.current_status
      this.items = this.COPY(this.items)
    },

    async openBusiness(item) {
      this.displayAddEvent = false
      this.navigation = 'info'
      this.entityData = await this.GET_BUSINESS_CRM_INFO(item.alias)
      this.displayDialog = true
    },

    getColor(item) {
      switch(item.status) {
        case 'trial' : return this.wsBACKGROUND
        case 'active' : return this.wsSUCCESS
        case 'failed' : return this.wsWARNING
        case 'runaway' : return this.wsATTENTION
      }
    },
    // technical
    getEventTypeIcon(type) {
      switch (type) {
        case 'call' : return 'mdi-phone'
        case 'bug' : return 'mdi-bug'
        default: return 'mdi-face-agent'
      }
    },
    getIcon(item) {
      if ( item.status === 'trial' || item.current_status === 'active') {
        return 'mdi-check-circle-outline'
      }

      return 'mdi-close-circle-outline'

    },
    initDates() {
      // Get the current date
      const currentDate = new Date();

      const startDate = new Date(2022, 6); // Note: Months are 0-indexed, so 6 represents July.
      const monthsAndYears = [];
      const uniqueYears = [];

      while (startDate <= currentDate) {
        const month = startDate.getMonth() + 1; // Adding 1 to convert to 1-based index
        const year = startDate.getFullYear();

        // Add the month and year to the monthsAndYears array
        monthsAndYears.push({ month: month.toString(), year: year.toString() });

        // Add the year to the uniqueYears array if it's not already there
        if (!uniqueYears.includes(year.toString())) {
          uniqueYears.push(year.toString());
        }

        // Move to the next month
        startDate.setMonth(startDate.getMonth() + 1);

        this.months = monthsAndYears
        this.years = uniqueYears
      }

      this.selectedYear = uniqueYears[uniqueYears.length - 1]
      this.selectedMonth = monthsAndYears[monthsAndYears.length - 1].month

    },
    async initPage() {
      let data = {
        month : this.selectedMonth,
        year : this.selectedYear
      }
      let result = await this.GET_MONTH_REGISTER_STATISTICS(data)
      if ( !result ) {
        return this.ERROR
      }
      this.items = result.items
    }
  },
  mounted() {
    this.initDates()
  }
}
</script>

<style scoped>

</style>